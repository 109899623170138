import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const Contact = () => (
    <div id='contact-container'>
        <div>
            <a href='https://www.linkedin.com/in/lucas-bos-782aba145/'>
                <FontAwesomeIcon icon={faLinkedin} size='2x' />
            </a>
            <a href='https://github.com/LCSsuper'>
                <FontAwesomeIcon icon={faGithub} size='2x' />
            </a>
            <a href='mailto:lcssuper@gmail.com'>
                <FontAwesomeIcon icon={faEnvelope} size='2x' />
            </a>
        </div>
    </div>
);

export default Contact;