import React, { useState, useContext } from "react";
import ScrollIntoView from "react-scroll-into-view";

const Context = React.createContext();

const ScrollTo = ({ selector, children, menuItem }) => {
    const menu = useContext(Context);

    return (
        <ScrollIntoView
            alignToTop={true}
            selector={selector}
            className={menu === menuItem ? "active" : ""}
        >
            {children}
        </ScrollIntoView>
    );
};

const Header = ({ menu }) => {
    const [dropdown, setDropdown] = useState(false);

    return (
        <div>
            <Context.Provider value={menu}>
                <div id="header" className={dropdown ? "is-active" : ""}>
                    <ScrollTo selector="#info" menuItem="info">
                        Lucas Bos
                    </ScrollTo>
                    <ScrollTo selector="#ervaring" menuItem="skills">
                        Ervaring
                    </ScrollTo>
                    <ScrollTo selector="#projecten" menuItem="projects">
                        Projecten
                    </ScrollTo>
                    <ScrollTo selector="#footer" menuItem="contact">
                        Contact
                    </ScrollTo>
                    <div
                        onClick={() => setDropdown(!dropdown)}
                        id="menu"
                        className={`hamburger hamburger--slider ${
                            dropdown ? "is-active" : ""
                        }`}
                    >
                        <div className="hamburger-box">
                            <div className="hamburger-inner"></div>
                        </div>
                    </div>
                </div>
                <div
                    id="dropdown"
                    onClick={() => setDropdown(!dropdown)}
                    className={dropdown ? "is-active" : ""}
                >
                    <ScrollTo selector="#ervaring" menuItem="skills">
                        Ervaring
                    </ScrollTo>
                    <ScrollTo selector="#projecten" menuItem="projects">
                        Projecten
                    </ScrollTo>
                    <ScrollTo selector="#footer" menuItem="contact">
                        Contact
                    </ScrollTo>
                </div>
            </Context.Provider>
        </div>
    );
};

export default Header;
