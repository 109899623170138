import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCopyright,
    faDatabase,
    faCode,
    faCodeBranch,
    faTasks,
} from "@fortawesome/free-solid-svg-icons";
import {
    faNodeJs,
    faJava,
    faPhp,
    faHtml5,
    faCss3,
    faJs,
    faReact,
    faAngular,
    faJira,
    faAmazon,
} from "@fortawesome/free-brands-svg-icons";
import { ParallaxProvider } from "react-scroll-parallax";

import Project from "./Project";
import Header from "./Header";
import Info from "./Info";
import Skill from "./Skill";
import Contact from "./Contact";
import Title from "./Title";
import useMediaQuery from "../hooks/useMediaQuery";

const skills = [
    {
        title: "Backend",
        items: [
            { icon: faNodeJs, text: "Node.JS" },
            { icon: faJava, text: "Java" },
            { icon: faCode, text: "C#" },
            { icon: faPhp, text: "PHP" },
            { icon: faDatabase, text: "PostgreSQL" },
            { icon: faDatabase, text: "Firebase" },
        ],
    },
    {
        title: "Frontend",
        items: [
            { icon: faHtml5, text: "HTML5" },
            { icon: faCss3, text: "CSS3" },
            { icon: faJs, text: "JavaScript (ES6)" },
            { icon: faReact, text: "React 16.8" },
            { icon: faAngular, text: "Angular 2" },
            { icon: faCode, text: "Ionic 4" },
            { icon: faCode, text: "Swift 4.2" },
        ],
    },
    {
        title: "Tools en methodieken",
        items: [
            { icon: faCodeBranch, text: "Git" },
            { icon: faJira, text: "Jira" },
            { icon: faTasks, text: "Scrum / Kanban" },
            { icon: faAmazon, text: "Amazon Web Services" },
        ],
    },
    {
        title: "Werkervaring",
        items: [
            {
                image: "images/jobs/thecircle.png",
                text: "The Circle",
                subtitle: "Junior programmeur (2016-2017)",
            },
            {
                image: "images/jobs/intergrip.png",
                text: "Intergrip",
                subtitle: "Junior webontwikkelaar (2017-2018)",
            },
            {
                image: "images/jobs/minox.png",
                text: "Minox",
                subtitle: "Medior software engineer (2019-heden)",
            },
        ],
    },
];

const projects = [
    {
        title: "Language trainer",
        description:
            "Met de language trainer is het mogelijk om talen te oefenen. Dit kan door een subset of de gehele bibliotheek te selecteren. De taal kan twee kanten op geoefend worden (japans->engels, engels->japans). Meerdere talen worden ondersteund! ",
        type: "landscape",
        color: "#f47c58",
        textAlign: "right",
        links: [
            {
                type: "github",
                link: "https://github.com/LCSsuper/japanesetrainer",
            },
            { type: "site", link: "https://trainer.lucasbos.dev" },
        ],
        images: [
            "/images/learner/app-1.png",
            "/images/learner/app-2.png",
            "/images/learner/app-3.png",
        ],
    },
    {
        title: "Ticketo",
        description:
            "Ticketo is een mobiele applicatie waar de gebruiker zijn bonnetjes in kan scannen en kan categoriseren. Met OCR kan de app de totaalprijs van de bonnetjes automatisch vinden. De app is gemaakt in Swift en Ionic om zo de verschillen tussen hybrid en native te ondervinden. De data van de app wordt opgeslagen in een Firebase database. ",
        type: "portrait",
        color: "#f47c58",
        textAlign: "left",
        links: [
            { type: "github", link: "https://github.com/LCSsuper/Ticketo" },
        ],
        images: [
            "/images/ticketo/app-1.jpg",
            "/images/ticketo/app-2.jpg",
            "/images/ticketo/app-3.jpg",
            "/images/ticketo/app-4.jpg",
            "/images/ticketo/app-5.jpg",
        ],
    },
    {
        title: "Spotify - Trådfri",
        description:
            "Een webapp die de IKEA Trådfri verlichting mee kleurt met de albumhoes die beluisterd wordt op Spotify. Deze webapp heeft een React frontend en een Node.JS backend. De webapp maakt gebruik van de Spotify API en de IKEA Trådfri API.",
        type: "landscape",
        color: "#f47c58",
        textAlign: "right",
        links: [
            {
                type: "github",
                link: "https://github.com/LCSsuper/SpotifyTradfri",
            },
        ],
        images: [
            "/images/spotify-tradfri/app-1.png",
            "/images/spotify-tradfri/app-2.png",
            "/images/spotify-tradfri/app-3.png",
        ],
    },
    {
        title: "Clara de chatbot",
        description:
            "Samen met een team heb ik onderzoek gedaan naar conversational user interfaces. Hierbij hebben wij een chatbot geschreven met het Microsoft Bot Framework en Node.JS. Deze chatbot heet Clara en heeft als doeleind het ondersteunen van huisartsenposten. Met dit project hebben we op ICA presents de 'Revolutionary Research Award' gewonnen.",
        type: "portrait",
        color: "#f47c58",
        textAlign: "left",
        links: [
            { type: "github", link: "https://github.com/nielwijers/NoTSCUI" },
            {
                type: "image",
                link: "https://www.facebook.com/ICApresents/photos/a.347170498714631/1664073630357638/?type=3&theater",
            },
            { type: "pdf", link: "/pdf/Conversational_User_Interfaces.pdf" },
        ],
        images: [
            "/images/clara/app-1.png",
            "/images/clara/app-2.png",
            "/images/clara/app-3.png",
            "/images/clara/app-4.png",
            "/images/clara/app-5.png",
        ],
    },
    {
        title: "Beatmaker",
        description:
            "De beatmaker is een webapp waarmee snel een beat gemaakt kan worden. Deze app is gemaakt met React en gebruikt o.a. Howler en Mobx. De gemaakte beat wordt opgeslagen met localStorage.",
        type: "landscape",
        color: "#f47c58",
        textAlign: "right",
        links: [
            { type: "github", link: "https://github.com/LCSsuper/Beatmaker" },
            { type: "site", link: "https://lucasbos.dev/beatmaker" },
        ],
        images: ["/images/beatmaker/app-1.png"],
    },
];

const App = () => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [menu, setMenu] = useState("info");
    const initRefs = [];
    let refs = [];

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        refs = [...initRefs];

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        refs.forEach((ref, index) => {
            if (ref !== null) {
                const rect = ref.getBoundingClientRect();
                if (rect.y > -50 && rect.y < 50) {
                    setMenu(["info", "skills", "projects"][index]);
                } else if (index === 3 && window.innerHeight - rect.y > -100) {
                    setMenu("contact");
                }
            }
        });
    };

    return (
        <ParallaxProvider>
            <div id="app" className={prefersDarkMode ? "dark" : ""}>
                <div />
                <div id="app-inner">
                    <Header menu={menu} />
                    <Info propsRef={(r) => (initRefs[0] = r)} />
                    <Title
                        propsRef={(r) => (initRefs[1] = r)}
                        id="ervaring"
                        title="Ervaring"
                    />
                    <div id="ervaring-container">
                        {skills.map((skill, key) => (
                            <Skill skill={skill} key={key} index={key} />
                        ))}
                    </div>
                    <Title
                        propsRef={(r) => (initRefs[2] = r)}
                        id="projecten"
                        title="Mijn projecten"
                    />
                    {projects.map((project, key) => (
                        <Project project={project} key={key} />
                    ))}
                    <Title id="contact" title="Contact" />
                    <Contact onEnterViewport={() => setMenu("contact")} />
                    <div ref={(r) => (initRefs[3] = r)} id="footer">
                        <FontAwesomeIcon icon={faCopyright} />
                        {new Date().getFullYear()} Lucas Bos
                    </div>
                </div>
                <div />
            </div>
        </ParallaxProvider>
    );
};

export default App;
