import React from "react";

import Title from "./Title";

const Info = ({ propsRef }) => (
    <div id="info" ref={propsRef}>
        <div />
        <div className="animated animatedFadeInUp fadeInUp">
            <div id="info-container">
                <Title id="name" title="Lucas Bos" />
                Hoi! Ik ben Lucas, een fullstack webdeveloper. Ik werk aan het
                gehele spectrum van web applicaties. Ik design, bouw front- en
                backend, en werk aan cloud architectuur. Momenteel ben ik
                geïnteresseerd in serverless architectuur.
            </div>
            <div>
                <div id="profile">
                    <div className="content">
                        <img
                            src="/images/placeholders/updated-profile.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <div />
    </div>
);

export default Info;
