import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faImage, faFilePdf, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Parallax } from 'react-scroll-parallax';

const Context = React.createContext();
const truncValues = {
    title: 20,
    description: 700
};

const getIcon = key => {
    switch(key) {
        case 'github':
            return faGithub;
        case 'image':
            return faImage;
        case 'pdf':
            return faFilePdf;
        case 'site':
            return faPlayCircle;
        default:
            return;
    }
};

const trunc = (string, n, useWordBoundary) => {
    if (string.length <= n) { return string; }
    let subString = string.substr(0, n-1);
    return (useWordBoundary
            ? subString.substr(0, subString.lastIndexOf(' '))
            : subString) + '...';
};

const ProjectText = () => {
    const project = useContext(Context);

    return (
        <div className={`project-text ${project.textAlign}`}>
            <div className='project-title'>{trunc(project.title, truncValues.title, true)}</div>
            <div>{trunc(project.description, truncValues.description, true)}</div>
            <div className='project-links'>
                    {project.links.map((link, key) => (
                        <a key={key} href={link.link} download={link.type === 'pdf'}>
                            <FontAwesomeIcon icon={getIcon(link.type)}/>
                        </a>
                    ))}
                </div>
        </div>
    )
};

const ProjectImages = () => {
    const project = useContext(Context);

    const isIE = false || !!document.documentMode;
    const isFirefox = typeof InstallTrigger !== 'undefined';

    return (
        <div className={`project-inner ${project.type}-${project.images.length} ${project.textAlign}`} >
            <div className='content' style={{background: project.color}}>
                {(!isIE && !isFirefox) ? (
                    <Parallax y={[-10, 10]}>
                        <div>
                            {project.images.map((image, key) => {
                                return <img src={image} alt='' key={key} />
                            })}
                        </div>
                    </Parallax>
                ) : (
                    <div>
                        {project.images.map((image, key) => {
                            return <img src={image} alt='' key={key} />
                        })}
                    </div>
                )}
            </div>
        </div>
    )
};

const Error = ({ project }) => (
    <div className='error'>
        <h2>Couldn't render project: The type with the amount of images is not supported.</h2>
        <h4>With type 'landscape' there can be 3 or 4 images</h4>
        <h4>With type 'portrait' there can be 5 or 6 images</h4>
        <h4>Current type and amount: {project.type} with {project.images.length} images</h4>
    </div>
)

const Project = ({ project, project: { type, images } }) => {

    if (
        (type === 'landscape' && images.length !== 1 && images.length !== 3 && images.length !== 4)
        || (type === 'portrait' && images.length !== 5 && images.length !== 6)
    ) {
        return <Error project={project} />
    }

    return (
        <div className='project-container'>
            <div className='full-container'>
                <Context.Provider value={project}>
                    <ProjectText />
                    <ProjectImages />
                </Context.Provider>
            </div>
        </div>
    )
};

export default Project;
