import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Title from "./Title";
import useOnScreen from "../hooks/useOnScreen";

const Ervaring = ({ skill, index }) => {
    const [fade, setFade] = useState(false);
    const innerRef = useRef(null);
    const isInViewPort = useOnScreen(innerRef);

    useEffect(() => {
        if (isInViewPort && !fade) setFade(true);
    }, [isInViewPort]);

    return (
        <div
            ref={innerRef}
            className={`animated animatedFadeInUp ${
                fade ? "fadeInUp" : ""
            } animationWait${index}`}
        >
            <Title
                id={`skill${index}`}
                customClass="ervaring-title"
                title={skill.title}
            />
            <div className="ervaring">
                {skill.items.map((item, key) => (
                    <div key={key}>
                        {item.icon && (
                            <FontAwesomeIcon icon={item.icon} size="2x" />
                        )}
                        {item.image && (
                            <div className="image">
                                <img src={item.image} alt={item.text} />
                            </div>
                        )}
                        <div className="text">
                            <span>{item.text}</span>
                            {item.subtitle && (
                                <div className="subtitle">{item.subtitle}</div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Ervaring;
